import LoadingView from '@/components/SharedLayout/LoadingView';
import useLoading from '@/hooks/useLoading';

/**
 * 此為LIFF 入口
 * 機器人開啟LIFF連結時會把要做的事情帶在參數t上
 * 例如：
 *    1. 解除綁定的網址為
 *       https://liff.line.me/1657501680-MYN5LGWl?t=LM_SIGNUP_UNLINK
 *    2. LINE 轉導到頁面時為
 *       https://lm-liff.chatok.io/?liff.state=%3Ft%3DLM_SIGNUP_UNLINK
 *    3. LIFF SDK會將其轉為
 *       https://lm-liff.chatok.io/?t=LM_SIGNUP_UNLINK
 *
 * 以下為前端站台需處理的作業
 * LM-LIFF站台，已分離至SharedLayoutLiff
 *   1. 詢問轉導API event
 *      1.1 100101 直接轉導
 *      1.2 100102 顯示資格不符 or 確認後轉導
 *
 * LM-Signup站台，已分離至SharedLayoutSignup
 *   1. LM_SIGNUP_LINK：註冊/綁定頁
 *   2. LM_SIGNUP_UNLINK：解除綁定頁
 *
 * @returns
 */
const IndexPage = () => {
  const { isLoading } = useLoading();

  return !isLoading ? <LoadingView /> : null;
};

export default IndexPage;

export const Head = () => <title>LINE MOBILE</title>;
